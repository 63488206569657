<template>
  <section class="policy-list">
    <div class="margin-bottom">
      <div class="flex flex-justify header-title-back">
        <h2>Clients</h2>
        <div>
          <v-button
            class="button-theme withe-color mg-r-8"
            @click="resetFilter">
            <span>
              Clear
            </span></v-button>
          <v-button
            class="button-theme"
            @click="handlerCreate">
            <font-awesome-icon
              class="withe-color"
              icon="user-plus"></font-awesome-icon>
            <span>
              Add Client
            </span>
          </v-button>
        </div>
      </div>
      <el-tabs
        v-model="status"
        @tab-click="handleClick">
        <el-tab-pane
          v-for="(item,index) in clientList"
          :key = index
          :label="item.name"
          :name="item.id"></el-tab-pane>
      </el-tabs>
      <div class="flex title-tip">
        <div class="flex">
          <span>Total Clients </span>
          <span class="number"> {{referrer_num}}</span>
        </div>
      </div>
    </div>
    <div class="show-table-maxscreen">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-class-name="'table-header'"
        :row-key="getRowId"
        @row-click="rowClick">
        <el-table-column
          prop="agency_code"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Client Name</span>
              <v-input
                v-model="client_name"
                placeholder="Search"
                @change="handlerChange"></v-input>
            </div>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.type==1">
              <font-awesome-icon
                class="icon-gray"
                :class="{'red-color':scope.row.status==2}"
                icon="user"></font-awesome-icon>
            </span>
            <span v-else>
              <font-awesome-icon
                class="icon-gray"
                :class="{'red-color':scope.row.status==2}"

                icon="building"></font-awesome-icon>
            </span>
            <span
              :class="{'red-color':scope.row.status==2}"
              class="mg-l-10">{{scope.row.name||'-'}}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="id_no"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>HKID/ Passport / BR / CI</span>
              <v-input
                v-model="id_no"
                placeholder="Search"
                @change="handlerChange"></v-input>
            </div>
          </template>
          <!--          <template slot-scope="scope">-->
          <!--            <span >{{scope.row.id_no}}</span>-->
          <!--          </template>-->
        </el-table-column>


        <el-table-column
          prop="policy_number"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Number of Policy</span>
              <v-min-max
                v-model="minMax"
                @change="handlerChange"></v-min-max>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="claim_number"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Total Claim Records</span>
              <v-min-max
                v-model="claim_min"
                @change="handlerChange"></v-min-max>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :reserve-selection='true'
          type="selection"
          :class-name="'selection_none'"
          prop="order_no"
          width="55">
        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>

                <v-row-col>
                  <span class="is-bold">Client Name</span>
                  <v-input
                    v-model="client_name"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">HKID/ Passport / BR / CI</span>
                  <v-input
                    v-model="id_no"
                    placeholder="Search"
                    @change="handlerChange"></v-input>
                </v-row-col>

                <v-row-col>
                  <span class="is-bold">Number of Policy</span>
                  <v-min-max
                    v-model="minMax"
                    @change="handlerChange"></v-min-max>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Total Claim Records</span>
                  <v-min-max
                    v-model="claim_min"
                    @change="handlerChange"></v-min-max>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick(item)">

        <div
          class="tr">
          <span>Client Name</span>
          <span  :class="{'red-color':item.status==2}">
            <span v-if="item.type==1">
              <font-awesome-icon
                class="icon-gray"
                :class="{'red-color':item.status==2}"
                icon="user"></font-awesome-icon>
            </span>
            <span v-else>
              <font-awesome-icon
                class="icon-gray"
                :class="{'red-color':item.status==2}"

                icon="building"></font-awesome-icon>
            </span>
            <span class="mg-l-10">
              {{item.name}}
            </span>
          </span>
        </div>

        <div
          class="tr">
          <span>HKID/ Passport / BR / CI</span>
          <span>{{item.id_no}}</span>
        </div>

        <div
          class="tr">
          <span>Number of Policy</span>
          <span>{{item.policy_number}}</span>
        </div>
        <div
          class="tr">
          <span>Total Claim Records</span>
          <span>{{item.claim_number}}</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'

import {getClientList} from '@api/clients'
import {mapActions} from 'vuex'
import MixinFilter from '@/mixins/filter'
export default {
  name: 'memberClients',
  components:{
  },
  mixins: [MixinOptions,MixinFormat,MixinFilter],
  props:{
  },
  data(){

    return {
      show:false,

      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        to:'',
        per_page:1,
        size:20
      },
      tableData: [],
      referrer_num:0,
      referrer_commission_vl:0,
      status:0,
      id_no:'',
      client_name:'',
      minMax:'',
      claim_min:'',
      filterData:{
        status:'0',
        id_no:'',
        client_name:'',
        minMax:'',
        claim_min:'',
      },
      filterTableKey:'clients'
    }
  },
  computed:{
  },
  created(){
    // this.network().getReferrerSummary()
    this.network().getClientList()
  },
  methods:{
    ...mapActions('common',['updateGlobalOptions']),
    ...mapActions('claim',['setClaimTab']),
    getRowId (row) {
      return row.order_no
    },
    handleClick(){
      this.pageConfig.currentPage = 1
      this.network().getClientList()
    },
    handlerRefresh(params){
      this.network().createReferrer(params)
    },
    handlerCreate(){
      this.$router.push('/member/clients/create')
      // this.$refs.createDialog.showReferDialog()
    },
    rowClick({client_id}){
      this.setClaimTab('0')
      this.updateFilter()
      this.$router.push('/member/clients/detail/'+client_id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().getClientList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().getClientList()
    },
    handlerChange(){
      this.pageConfig.currentPage = 1
      this.network().getClientList()
    },
    network() {
      return {
        getClientList: async () => {
          const { data } = await getClientList({
            size:this.pageConfig.size,
            page: this.pageConfig.currentPage,
            id_no:this.id_no,
            client_name:this.client_name,
            policy_min:this.minMax?this.minMax[0]:'',
            policy_max:this.minMax?this.minMax[1]:'',
            claim_min:this.claim_min?this.claim_min[0]:'',
            claim_max:this.claim_min?this.claim_min[1]:'',
            status:this.status
          })
          this.tableData = data.data
          let {meta} =data
          this.pageConfig.total = meta.total
          this.referrer_num = meta.total
        },
      }
    },
    toFixed(data) {
      const arr = String(data).split('.')
      let num = arr[0] // 整数部分
      let resultArr = []
      while (num.length > 0) {
        resultArr.unshift(num.slice(-3))
        num = num.slice(0, -3)
      }
      let result = resultArr.join(',')
      return result
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/css/policyList';
.margin-bottom{
  margin-bottom: 20px;
}
.header-title-back{
  h2{
    font-size: 36px;
  }
}
.icon-gray{
  color: $disable;
}
.title-tip{
  color: #4A5568;
  font-size: 16px;
  font-weight: 600;
  .number{
    font-size: 16px;
    font-weight: normal;
    margin: 0 25px 0 12px;
  }
}
.header-title-back{
  .button-theme{
    font-size: 16px;
    font-weight: 600;
    color: #fff!important;
    span{
      color: #fff!important;
    }
    .btn-label{
      color: #fff!important;
    }
    .withe-color{
      color: white;
    }
  }
}
/deep/.selection_none{
  .cell{
    display: none;
  }
}

</style>
